import { safeSetLocalStorage } from "@product/scmp-sdk";
import { addMinutes } from "date-fns";
import { atom } from "jotai";
import Cookies from "js-cookie";
import { config } from "shared/data";
import { RegisterEmailExpirationDateLocalStorageKey } from "./consts";
import { getAccountState, maskValue } from "./helpers";
export const accountAtom = atom(getAccountState());
export const registerEmailCookieAtom = (() => {
  const key = "register_email";
  const domain = config.account.cookieDomain;
  const value = atom({
    expirationDate: typeof window === "undefined" ? null : window.localStorage.getItem(RegisterEmailExpirationDateLocalStorageKey),
    registerEmail: Cookies.get(key) ?? null
  });
  const wrapped = atom(get => get(value), (_get, set, newValue) => {
    if (newValue) {
      const masked = maskValue(newValue);
      const expirationDelayInMinutes = 5;
      const expirationDate = addMinutes(new Date(), expirationDelayInMinutes);
      Cookies.set(key, masked, {
        domain,
        expires: expirationDate
      });
      safeSetLocalStorage(RegisterEmailExpirationDateLocalStorageKey, expirationDate.toISOString());
      set(value, {
        expirationDate: expirationDate.toISOString(),
        registerEmail: masked
      });
    } else {
      Cookies.remove(key, {
        domain
      });
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(RegisterEmailExpirationDateLocalStorageKey);
      }
      set(value, {
        expirationDate: null,
        registerEmail: null
      });
    }
  });
  return wrapped;
})();