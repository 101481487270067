import styled from "@emotion/styled";

import { ZIndexLayer } from "shared/lib/styles";

export const Container = styled.div`
  position: absolute;
  inset-inline: 0 0;
  z-index: ${ZIndexLayer.GoogleOneTapSignIn};

  inline-size: min-content;
  margin-inline-start: auto;
`;
