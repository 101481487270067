import { ZIndexLayer as SdkZIndexLayer } from "@product/scmp-sdk";
export let ZIndexLayer = function (ZIndexLayer) {
  ZIndexLayer[ZIndexLayer["AlertBox"] = 1700] = "AlertBox";
  ZIndexLayer[ZIndexLayer["AppBar"] = 1300] = "AppBar";
  ZIndexLayer[ZIndexLayer["AppTooltip"] = 1100] = "AppTooltip";
  ZIndexLayer[ZIndexLayer["ArticleAuthorFollowButton"] = 1001] = "ArticleAuthorFollowButton";
  ZIndexLayer[ZIndexLayer["Drawer"] = 1400] = "Drawer";
  ZIndexLayer[ZIndexLayer["DropdownSelect"] = 1350] = "DropdownSelect";
  ZIndexLayer[ZIndexLayer["EditionInvitation"] = 1900] = "EditionInvitation";
  ZIndexLayer[ZIndexLayer["GoogleOneTapSignIn"] = 2000] = "GoogleOneTapSignIn";
  ZIndexLayer[ZIndexLayer["HamburgerMenu"] = 1800] = "HamburgerMenu";
  ZIndexLayer[ZIndexLayer["LiveUpdateAlert"] = 4000] = "LiveUpdateAlert";
  ZIndexLayer[ZIndexLayer["Modal"] = SdkZIndexLayer.Modal] = "Modal";
  ZIndexLayer[ZIndexLayer["NewsletterArticleFloatingWidget"] = 1000] = "NewsletterArticleFloatingWidget";
  ZIndexLayer[ZIndexLayer["NewsletterArticleIssues"] = 1200] = "NewsletterArticleIssues";
  ZIndexLayer[ZIndexLayer["OnboardingDialog"] = 3000] = "OnboardingDialog";
  ZIndexLayer[ZIndexLayer["PlusDatePicker"] = 1202] = "PlusDatePicker";
  ZIndexLayer[ZIndexLayer["PlusListingPopup"] = 2200] = "PlusListingPopup";
  ZIndexLayer[ZIndexLayer["PlusPopup"] = 2100] = "PlusPopup";
  ZIndexLayer[ZIndexLayer["SnackBar"] = 1600] = "SnackBar";
  ZIndexLayer[ZIndexLayer["StoryCardCarouselWidget"] = 1102] = "StoryCardCarouselWidget";
  ZIndexLayer[ZIndexLayer["TakeoverAd"] = 1101] = "TakeoverAd";
  ZIndexLayer[ZIndexLayer["Tooltip"] = SdkZIndexLayer.Tooltip] = "Tooltip";
  return ZIndexLayer;
}({});