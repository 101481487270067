import { cache } from "@emotion/css"; // eslint-disable-line @emotion/no-vanilla
import { CacheProvider, css, Global } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { theme } from "@product/scmp-sdk";
import { FlagProvider } from "@unleash/nextjs/client";
import { AnimatePresence, domAnimation, LazyMotion } from "framer-motion";
import { Provider as JotaiProvider } from "jotai";
import type { NextApp } from "next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RelayEnvironmentProvider } from "react-relay";
import { useRelayNextjs } from "relay-nextjs/app";
import { SWRConfig } from "swr";
import { resolveFetch } from "unleash-proxy-client";

import { PageTransition } from "shared/components/common/page-transition";
import { config } from "shared/data";
import { useSentrySetup } from "shared/lib/sentry/hooks";
import { globalCss } from "shared/lib/styles";

import { AdvertisementScripts } from "scmp-app/components/advertisement";
import { AppInitializer } from "scmp-app/components/app-initializer";
import { AppLinkingData } from "scmp-app/components/app-linking-data";
import { TrackingScripts } from "scmp-app/components/tracking";
import { OptimizelyContextProvider } from "scmp-app/lib/optimizely/context";
import type { PageProps } from "scmp-app/lib/relay-nextjs";
import { createClientEnvironmentByPageProps } from "scmp-app/lib/relay-nextjs/helpers";

import { AfterPageLayoutContainerElementID, FooterElementID } from "./_app/consts";
import {
  DynamicArticlePianoPaywallProvider,
  DynamicC2paProvider,
  DynamicRosettaContextProvider,
  DynamicSdkContextProvider,
  DynamicSnackbarProvider,
} from "./_app/dynamics";
import { AfterPageLayoutContainer, Content, FooterContainer, PageContainer } from "./_app/styles";

import "@product/rosetta-sdk/custom-piano-styles.css";

type FetchFunction_ = NonNullable<ReturnType<typeof resolveFetch>>;

const App: NextApp<PageProps> = ({ Component, err, pageProps }) => {
  const { env: environment, ...relayProps } = useRelayNextjs(pageProps, {
    createClientEnvironment: () => createClientEnvironmentByPageProps(pageProps),
  });

  useSentrySetup();

  const fetch: FetchFunction_ = function (url, options) {
    const fetch_ = resolveFetch() as FetchFunction_;
    const headers = options?.headers as Record<string, string>;
    delete headers["If-None-Match"];
    delete headers["Cache-control"];
    delete options?.cache;
    return fetch_(url, options);
  };

  // For supporting layout per page
  const renderLayout = () => {
    const PageComponent = <Component {...pageProps} {...relayProps} err={err} />;
    if (Component.getLayout) {
      return Component.getLayout(PageComponent);
    }

    return (
      <PageContainer
        $contentResponsivePadding={pageProps.contentConfiguration?.responsivePadding}
        $pageResponsivePadding={pageProps.pageConfiguration?.responsivePadding}
      >
        <AppInitializer pageProps={pageProps}>
          <Content>
            <AnimatePresence initial={false} mode="wait">
              <PageTransition animateKey={pageProps.animateKey}>{PageComponent}</PageTransition>
            </AnimatePresence>
          </Content>
        </AppInitializer>
        <FooterContainer id={FooterElementID} />
      </PageContainer>
    );
  };
  return (
    <OptimizelyContextProvider>
      <FlagProvider config={{ fetch, refreshInterval: 3600 }}>
        <AppLinkingData />
        <Global
          styles={css`
            ${globalCss}

            ${pageProps.extraGlobalCss}
          `}
        />
        <LazyMotion features={domAnimation}>
          <MuiThemeProvider theme={theme}>
            <JotaiProvider>
              <AdvertisementScripts />
              <TrackingScripts />

              <SWRConfig
                value={{
                  onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
                    if (retryCount >= 5) return;
                    setTimeout(() => revalidate({ retryCount }), 5000);
                  },
                  provider: () => new Map(),
                }}
              >
                <RelayEnvironmentProvider environment={environment}>
                  <DynamicArticlePianoPaywallProvider>
                    <DynamicSnackbarProvider>
                      <DynamicSdkContextProvider>
                        <DynamicRosettaContextProvider>
                          <DynamicC2paProvider>
                            <GoogleReCaptchaProvider reCaptchaKey={config.account.recaptchaSiteKey}>
                              <CacheProvider value={cache}>
                                {renderLayout()}
                                <AfterPageLayoutContainer id={AfterPageLayoutContainerElementID} />
                              </CacheProvider>
                            </GoogleReCaptchaProvider>
                          </DynamicC2paProvider>
                        </DynamicRosettaContextProvider>
                      </DynamicSdkContextProvider>
                    </DynamicSnackbarProvider>
                  </DynamicArticlePianoPaywallProvider>
                </RelayEnvironmentProvider>
              </SWRConfig>
            </JotaiProvider>
          </MuiThemeProvider>
        </LazyMotion>
      </FlagProvider>
    </OptimizelyContextProvider>
  );
};

App.displayName = "App";

export default App;
