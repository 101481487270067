import styled from "@emotion/styled";

import { setFullViewportWidthStyles } from "shared/lib/styles";

export const Container = styled.div`
  ${setFullViewportWidthStyles()}

  display: flex;
  justify-content: center;

  padding-block-start: 212px;
`;
