import "core-js/modules/es.array.includes.js";
import { PageType } from "@product/scmp-sdk";
import { useRouter } from "next/router";
export const useCurrentPageType = () => {
  const {
    query: {
      pageType
    }
  } = useRouter();
  if (!isPageType(pageType)) return PageType.Others;
  return pageType;
};
export function isPageType(data) {
  return Object.values(PageType).includes(data);
}