import dynamic from "next/dynamic";

import type { Props as ArticlePianoPaywallProviderProps } from "scmp-app/components/article/article-piano-paywall/contexts";
import type { Props as SnackbarProviderProps } from "scmp-app/components/common/snackbar";
import type { Props as C2paProviderProps } from "scmp-app/components/schema-render/common/image/starling-image-popover/contexts";
import type { Props as RosettaContextProviderProps } from "scmp-app/lib/rosetta";

import type { Props as SdkContextProviderProps } from "./contexts";

export const DynamicSnackbarProvider = dynamic<SnackbarProviderProps>(() =>
  import("scmp-app/components/common/snackbar").then(module => module.SnackbarProvider),
);

export const DynamicSdkContextProvider = dynamic<SdkContextProviderProps>(() =>
  import("./contexts").then(module => module.SdkContextProvider),
);

export const DynamicRosettaContextProvider = dynamic<RosettaContextProviderProps>(() =>
  import("scmp-app/lib/rosetta").then(module => module.RosettaContextProvider),
);

export const DynamicC2paProvider = dynamic<C2paProviderProps>(() =>
  import("scmp-app/components/schema-render/common/image/starling-image-popover/contexts").then(
    module => module.C2paProvider,
  ),
);

export const DynamicArticlePianoPaywallProvider = dynamic<ArticlePianoPaywallProviderProps>(() =>
  import("scmp-app/components/article/article-piano-paywall/contexts").then(
    module => module.ArticlePianoPaywallProvider,
  ),
);
