import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "shared/lib/styles";

export const Container = styled.div`
  min-block-size: 39px;
  padding-block: 12px;
  padding-inline-start: 20px;

  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
  border-block-end: 1px solid #cccccc;

  ${setFullViewportWidthStyles()}

  ${theme.breakpoints.up("tablet")} {
    display: flex;
    justify-content: center;

    padding-inline-start: 32px;
  }
`;
