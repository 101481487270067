import { notEmpty } from "@product/scmp-sdk";
import type { ReadonlyURLSearchParams } from "next/navigation";

import type { PlusDetailedListing } from "./types";

export const QueryParameterName = "plus-detail";
export const QuerySeparator = ":";

type EncodedPlusDetailedListingQuery = ReturnType<typeof encodePlusDetailedListingQuery>;

type KeysOfEncodedPlusDetailedListingQuery = Array<KeysOfUnion<EncodedPlusDetailedListingQuery>>;

/** ref: https://stackoverflow.com/a/49402091/5235017 */
type KeysOfUnion<T> = T extends T ? keyof T : never;

export function decodePlusDetailedListingQuery(
  searchParameters: ReadonlyURLSearchParams,
): PlusDetailedListing | undefined {
  const plusDetailQueryString = searchParameters.get(QueryParameterName) ?? "";
  const [type, entityUuid] = plusDetailQueryString.split(QuerySeparator).filter(notEmpty);

  switch (type) {
    case "author":
    case "china-future-tech-briefing":
    case "daily-pulse":
    case "fact-sheet":
    case "featured-opinion":
    case "interactives":
    case "open-questions-series":
    case "quick-digest":
    case "topic":
    case "topic-without-related-contents":
      return { entityUuid, type };
  }
}

export function encodePlusDetailedListingQuery(plusDetailedListing: PlusDetailedListing) {
  const { type: _type, ...rest } = plusDetailedListing;
  switch (plusDetailedListing.type) {
    case "author":
    case "topic":
    case "topic-without-related-contents":
      return {
        [QueryParameterName]: [plusDetailedListing.type, plusDetailedListing.entityUuid].join(
          QuerySeparator,
        ),
        ...rest,
      };
    case "china-future-tech-briefing":
    case "daily-pulse":
    case "fact-sheet":
    case "featured-opinion":
    case "interactives":
    case "open-questions-series":
    case "quick-digest":
      return { [QueryParameterName]: plusDetailedListing.type, ...rest };
  }
}

export const keysOfEncodedPlusDetailedListingQuery: KeysOfEncodedPlusDetailedListingQuery = [
  QueryParameterName,
  "entityUuid",
  "pgtype",
];
