import { type FunctionComponent, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";

import {
  ExperimentAPINames,
  useActiveExperiment,
  useOptimizelyContext,
} from "scmp-app/lib/optimizely";
import type { trendingNavBarSwiperQueueQuery } from "scmp-app/queries/__generated__/trendingNavBarSwiperQueueQuery.graphql";

import {
  CenteredContainer,
  Divider,
  Item,
  StyledSwiper,
  StyledSwiperSlide,
  Trending,
} from "./styles";
type Props = {
  className?: string;
  edition: string;
};

type PwaSubnavMenuConfigItem = {
  title: string;
  url: string;
};

export const TrendingNavBarSwiper: FunctionComponent<Props> = ({ className, edition }) => {
  const data = useLazyLoadQuery<trendingNavBarSwiperQueueQuery>(
    graphql`
      query trendingNavBarSwiperQueueQuery {
        appConfig(filter: { entityId: "pwa_subnav_menu" }) {
          json
        }
      }
    `,
    {},
    { fetchPolicy: "store-or-network" },
  );

  const { fireEvent } = useOptimizelyContext();
  const { experiment } = useActiveExperiment(ExperimentAPINames.SubNavTrendingLabel);
  const isShowTrendingLabel = useMemo(
    () => experiment?.variation.name === "1",
    [experiment?.variation.name],
  );

  const subNavMenuConfig = data.appConfig?.json as Record<string, PwaSubnavMenuConfigItem[]>;
  if (!subNavMenuConfig || !subNavMenuConfig[edition]) return null;

  const items = subNavMenuConfig[edition];
  return (
    <CenteredContainer>
      {isShowTrendingLabel && <Trending>Trending</Trending>}
      <StyledSwiper
        className={className}
        cssMode={false}
        pagination={false}
        slidesOffsetAfter={20}
        slidesPerView="auto"
      >
        {items.map((item, index) => (
          <StyledSwiperSlide key={index}>
            <BaseLinkContextProvider
              customQueryParameters={{
                label: item.title,
                module: `oneline_menu_trending_${edition}`,
              }}
            >
              <Item
                anchorProps={{ title: item.title }}
                as={item.url}
                onClick={() => {
                  fireEvent?.({
                    eventName: "item_click",
                  });
                }}
                pathname={item.url}
              >
                {item.title}
              </Item>
            </BaseLinkContextProvider>
            {index !== items.length - 1 && <Divider>|</Divider>}
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
    </CenteredContainer>
  );
};

TrendingNavBarSwiper.displayName = "TrendingNavBarSwiper";
