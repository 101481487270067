import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/esnext.async-iterator.some.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
import { config, data } from "shared/data";
export const getAssetPrefix = () => {
  if (config.general.cdn.enabled) {
    return `https://${config.general.cdn.host}/${config.general.env}`;
  }
  return "";
};
export function getAbsoluteUrl(path) {
  let domain = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : config.general.siteUrl;
  if (!path) return domain;
  try {
    return new URL(path, domain).href;
  } catch {
    return domain;
  }
}
export function isScmpDomain(host) {
  return Object.values(data.scmpDomains).some(domain => host.endsWith(domain));
}