export const Article = "article";
export const Homepage = "homepage";
export const NotFound = "404";
export const ArticlePosties = "article_posties";
export const Brochure = "brochure";
export const Content = "content";
export const SectionPosties = "section_posties";
export const VideoPosties = "video_posties";
export const Series = "series";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PostiesPageTypes = [ArticlePosties, Brochure, Content, SectionPosties, VideoPosties];