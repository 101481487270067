import { notEmpty } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import Script from "next/script";

import { config, data } from "shared/data";
import { accountAtom } from "shared/lib/account";
import { isExternalScriptsDisabled } from "shared/lib/utils";

import { Container } from "./styles";

export const GoogleOneTapLoginContainer = () => {
  const { user } = useAtomValue(accountAtom);
  if (notEmpty(user?.uuid) || isExternalScriptsDisabled("google-one-tap-login")) return null;

  const state = JSON.stringify({
    appID: data.application.scmp,
    callbackUrl: config.account.authCallbackUrl,
    destination: window.location.href,
    gaProfileID: config.googleAnalytics.profileId,
  });

  return (
    <>
      <Script
        id="google-client-js"
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
      />
      <Container
        data-cancel_on_tap_outside="false"
        data-client_id={config.google.clientId}
        data-itp_support="true"
        data-login_uri={`${config.account.scmpAccountHost}/login/google-oneTap/return`}
        data-prompt_parent_id="g_id_onload"
        data-state={state}
        id="g_id_onload"
      />
    </>
  );
};
