import _extends from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["animateKey", "children"];
var __jsx = React.createElement;
import { m } from "framer-motion";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { getUrlPathWithoutQuery } from "shared/lib/utils";
export const PageTransition = _ref => {
  let {
      animateKey,
      children
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    asPath
  } = useRouter();
  // This is for preventing the transition effect when querystring is changed
  const currentUrl = useMemo(() => getUrlPathWithoutQuery(asPath), [asPath]);
  return __jsx(m.div, _extends({
    animate: {
      opacity: 1
    },
    exit: {
      opacity: 0
    },
    initial: {
      opacity: 0
    },
    key: animateKey ?? currentUrl,
    style: {
      willChange: "auto"
    }
  }, props), children);
};
PageTransition.displayName = "PageTransition";