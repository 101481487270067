;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"8833dce5e64a233fd5b8311b3bb03af3c0b73e21"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/production";import * as Sentry from "@sentry/nextjs";

import { config } from "shared/data";
import { sentryConfig } from "shared/lib/sentry/config";
import { supportedBrowsers } from "shared/lib/sentry/supported-browsers";

Sentry.init({
  ...sentryConfig,
  beforeSend: event => {
    // Filter out those browser is not in our supported list
    if (typeof navigator !== "undefined" && !supportedBrowsers.test(navigator.userAgent))
      return null;
    return event;
  },
  integrations: [
    Sentry.extraErrorDataIntegration({ depth: 6 }),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],
  tracesSampleRate: config.sentry.enableTracing ? 0.1 : 0,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});
