import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.async-iterator.reduce.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.async-iterator.map.js";
import "core-js/modules/esnext.iterator.map.js";
import capitalize from "lodash/capitalize";
export function getUrlPathWithoutQuery(pathname) {
  if (typeof window === "undefined") return pathname.split("?")[0];
  const parsedUrl = new URL(window.location.href);
  return parsedUrl.pathname;
}

/**
 * For getting window safety by checking window is exists or not
 */
export function getWindow() {
  return typeof window === "undefined" ? null : window;
}
export function isSsr() {
  return typeof window === "undefined";
}
export function parseArray(input) {
  if (Array.isArray(input)) return input;
  return [input];
}
export function parseQueryString(value) {
  if (typeof value !== "string") {
    return;
  }
  return value;
}
export function removeNullOrUndefinedInObject(object) {
  return Object.fromEntries(Object.entries(object).filter(_ref => {
    let [_, v] = _ref;
    return v !== null || v !== undefined;
  }));
}
export function sanitizeReadonlyArray(array) {
  if (!Array.isArray(array)) return [];
  return array.filter(v => v !== null) ?? [];
}

/**
 * Sorts an array of data items based on the order of a reference array, using the provided string keys.
 * Items that are not present in the reference array will be filtered out.
 **/
export function sortArrayByReferenceOrder(parameters) {
  const {
    data,
    order
  } = parameters;
  const lookup = data.array.reduce((map, item) => {
    map[data.idGetter(item)] = item;
    return map;
  }, {});
  return order.array.map(item => lookup[order.idGetter(item)]).filter(Boolean);
}
export function titleCase(input) {
  var _input$toLowerCase, _input$toLowerCase$re, _input$toLowerCase$re2;
  return input === null || input === void 0 || (_input$toLowerCase = input.toLowerCase()) === null || _input$toLowerCase === void 0 || (_input$toLowerCase$re = (_input$toLowerCase$re2 = _input$toLowerCase.replace(/\b\w/g, text => text.toUpperCase())).replace) === null || _input$toLowerCase$re === void 0 ? void 0 : _input$toLowerCase$re.call(_input$toLowerCase$re2, /'\w/g, text => text.toLowerCase());
}
const urlRegex = new RegExp("^(?:[a-z]+:)?//", "i");
export function isAbsoluteUrl(data) {
  return urlRegex.test(data);
}
export function isNumeric(data) {
  return typeof data === "number";
}
export function isString(data) {
  return typeof data === "string";
}
export function isValidUrl(data) {
  try {
    new URL(data);
    return true;
  } catch {
    return false;
  }
}
export function typedCapitalize(value) {
  return capitalize(value);
}