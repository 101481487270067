import _extends from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_e44ca7531af3a51d6663a646e2dfb970/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children"];
var __jsx = React.createElement;
import React from "react";
import { Suspense, useEffect, useState } from "react";

/**
 * This component will render the suspense in CSR for handling suspense boundary issue
 */
export const ClientSideSuspense = _ref => {
  let {
      children
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    0: show,
    1: setShow
  } = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  return show ? __jsx(Suspense, _extends({}, props, {
    "data-qa": "ClientSideSuspense-Suspense"
  }), children) : props.fallback;
};
ClientSideSuspense.displayName = "ClientSideSuspense";