import type { FunctionComponent } from "react";

import { isExternalScriptsDisabled } from "shared/lib/utils";

import { GoogleTagManagerScripts } from "./google-tag-manager/scripts";

export * from "./atoms";
export * from "./hooks";

export const TrackingScripts: FunctionComponent = () =>
  isExternalScriptsDisabled("tracking") ? null : (
    <>
      <GoogleTagManagerScripts />
    </>
  );

TrackingScripts.displayName = "TrackingScripts";
